import React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import { RichText } from 'prismic-reactjs';
import Layout from "../../components/layout";
import Title from "../../components/title";
 
export default ({ location, data }) => {

  const doc = data.prismic.allDocs.edges.slice(0,1).pop();
    if(!doc) return null;
  
  return (

    <Layout
      path={location.pathname}>

      <Title
        title={doc.node.title[0].text}
        width="max-w-xs md:max-w-md lg:max-w-xl" />

      <div className="container max-w-3xl mx-auto">

        {doc.node.body[0].fields.map(item => (
          <>
          <div className="richtext mx-auto container space-y-8">

            {item.video[0] && (
              <div className="bg-box_dark overflow-hidden">
                <div className="relative pb-16/9">
                  <video className="outline-none absolute w-full h-full object-cover" autoPlay muted loop playsinline>
                    <source src={item.video[0].text} type="video/mp4" />
                  </video>
                </div>
              </div>
            )}

            {RichText.render(item.description)}

          </div>
          <hr className="block my-16" />
          </>
        ))}

        {doc.node.next ? (
          <Link to={`/docs/${doc.node.next._meta.uid}`} className="font-medium text-xl text-accent">Next: {doc.node.next.title[0].text}</Link>
        ) : (
          <Link to='/docs' className="font-medium text-xl text-accent">Back to Docs</Link>
        )}

      </div>

    </Layout>

  )

}

export const query = graphql`
  query DocQuery($uid: String) {
    prismic {
      allDocs(uid: $uid) {
        edges {
          node {
            title
            body {
              ... on PRISMIC_DocBodySteps {
                fields {
                  description
                  video
                }
              }
            }
            next {
              ... on PRISMIC_Doc {
                title
                _meta {
                  uid
                }
              }
            }
          }
        }
      }
    }
  }
`